import {
  AED,
  AFN,
  ALL,
  AMD,
  ANG,
  AOA,
  ARS,
  AUD,
  AWG,
  AZN,
  BAM,
  BBD,
  BDT,
  BGN,
  BIF,
  BMD,
  BND,
  BOB,
  BRL,
  BSD,
  BWP,
  BYN,
  BZD,
  CAD,
  CDF,
  CHF,
  CLP,
  CNY,
  COP,
  CRC,
  Currency,
  CVE,
  CZK,
  DJF,
  DKK,
  DOP,
  DZD,
  EGP,
  ETB,
  EUR,
  FJD,
  FKP,
  GBP,
  GEL,
  GIP,
  GMD,
  GNF,
  GTQ,
  GYD,
  HKD,
  HNL,
  HTG,
  HUF,
  IDR,
  ILS,
  INR,
  ISK,
  JMD,
  JPY,
  KES,
  KGS,
  KHR,
  KMF,
  KRW,
  KYD,
  KZT,
  LAK,
  LBP,
  LKR,
  LRD,
  LSL,
  MAD,
  MDL,
  MGA,
  MKD,
  MMK,
  MNT,
  MOP,
  MUR,
  MVR,
  MWK,
  MXN,
  MYR,
  MZN,
  NAD,
  NGN,
  NIO,
  NOK,
  NPR,
  NZD,
  PAB,
  PEN,
  PGK,
  PHP,
  PKR,
  PLN,
  PYG,
  QAR,
  RON,
  RSD,
  RUB,
  RWF,
  SAR,
  SBD,
  SCR,
  SEK,
  SGD,
  SHP,
  SOS,
  SRD,
  SZL,
  THB,
  TJS,
  TOP,
  TRY,
  TTD,
  TWD,
  TZS,
  UAH,
  UGX,
  USD,
  UYU,
  UZS,
  VND,
  VUV,
  WST,
  XAF,
  XCD,
  XOF,
  XPF,
  YER,
  ZAR,
  ZMW,
} from '@dinero.js/currencies'
import { dinero, toDecimal, toSnapshot } from 'dinero.js'

export const currencyMap: Record<
  string,
  { currency: Currency<number>; locale: string; symbol: string; label: string }
> = {
  usd: {
    currency: USD,
    locale: 'en-US',
    symbol: '$',
    label: 'United States Dollar',
  },
  aed: {
    currency: AED,
    locale: 'ar-AE',
    symbol: 'د.إ',
    label: 'United Arab Emirates Dirham',
  },
  afn: { currency: AFN, locale: 'fa-AF', symbol: '؋', label: 'Afghan Afghani' },
  all: { currency: ALL, locale: 'sq-AL', symbol: 'Lek', label: 'Albanian Lek' },
  amd: { currency: AMD, locale: 'hy-AM', symbol: '֏', label: 'Armenian Dram' },
  ang: {
    currency: ANG,
    locale: 'nl-SX',
    symbol: 'ƒ',
    label: 'Netherlands Antillean Guilder',
  },
  aoa: {
    currency: AOA,
    locale: 'pt-AO',
    symbol: 'Kz',
    label: 'Angolan Kwanza',
  },
  ars: { currency: ARS, locale: 'es-AR', symbol: '$', label: 'Argentine Peso' },
  aud: {
    currency: AUD,
    locale: 'en-AU',
    symbol: '$',
    label: 'Australian Dollar',
  },
  awg: { currency: AWG, locale: 'nl-AW', symbol: 'ƒ', label: 'Aruban Florin' },
  azn: {
    currency: AZN,
    locale: 'az-AZ',
    symbol: '₼',
    label: 'Azerbaijani Manat',
  },
  bam: {
    currency: BAM,
    locale: 'bs-BA',
    symbol: 'KM',
    label: 'Bosnia-Herzegovina Convertible Mark',
  },
  bbd: {
    currency: BBD,
    locale: 'en-BB',
    symbol: '$',
    label: 'Barbadian Dollar',
  },
  bdt: {
    currency: BDT,
    locale: 'bn-BD',
    symbol: '৳',
    label: 'Bangladeshi Taka',
  },
  bgn: { currency: BGN, locale: 'bg-BG', symbol: 'лв', label: 'Bulgarian Lev' },
  bif: {
    currency: BIF,
    locale: 'fr-BI',
    symbol: 'FBu',
    label: 'Burundian Franc',
  },
  bmd: {
    currency: BMD,
    locale: 'en-BM',
    symbol: '$',
    label: 'Bermudian Dollar',
  },
  bnd: {
    currency: BND,
    locale: 'en-BN',
    symbol: 'BND',
    label: 'Brunei Dollar',
  },
  bob: {
    currency: BOB,
    locale: 'es-BO',
    symbol: 'Bs.',
    label: 'Bolivian Boliviano',
  },
  brl: {
    currency: BRL,
    locale: 'pt-BR',
    symbol: 'R$',
    label: 'Brazilian Real',
  },
  bsd: {
    currency: BSD,
    locale: 'en-BS',
    symbol: '$',
    label: 'Bahamian Dollar',
  },
  bwp: { currency: BWP, locale: 'en-BW', symbol: 'P', label: 'Botswana Pula' },
  byn: {
    currency: BYN,
    locale: 'be-BY',
    symbol: 'Br',
    label: 'Belarusian Ruble',
  },
  bzd: { currency: BZD, locale: 'en-BZ', symbol: '$', label: 'Belize Dollar' },
  cad: {
    currency: CAD,
    locale: 'en-CA',
    symbol: '$',
    label: 'Canadian Dollar',
  },
  cdf: {
    currency: CDF,
    locale: 'fr-CD',
    symbol: 'FC',
    label: 'Congolese Franc',
  },
  chf: { currency: CHF, locale: 'de-CH', symbol: 'CHF', label: 'Swiss Franc' },
  clp: { currency: CLP, locale: 'es-CL', symbol: '$', label: 'Chilean Peso' },
  cny: { currency: CNY, locale: 'zh-CN', symbol: '¥', label: 'Chinese Yuan' },
  cop: { currency: COP, locale: 'es-CO', symbol: '$', label: 'Colombian Peso' },
  crc: {
    currency: CRC,
    locale: 'es-CR',
    symbol: '₡',
    label: 'Costa Rican Colón',
  },
  cve: {
    currency: CVE,
    locale: 'pt-CV',
    symbol: '$',
    label: 'Cape Verdean Escudo',
  },
  czk: { currency: CZK, locale: 'cs-CZ', symbol: 'Kč', label: 'Czech Koruna' },
  djf: {
    currency: DJF,
    locale: 'fr-DJ',
    symbol: 'Fdj',
    label: 'Djiboutian Franc',
  },
  dkk: { currency: DKK, locale: 'da-DK', symbol: 'kr', label: 'Danish Krone' },
  dop: {
    currency: DOP,
    locale: 'es-DO',
    symbol: 'RD$',
    label: 'Dominican Peso',
  },
  dzd: {
    currency: DZD,
    locale: 'ar-DZ',
    symbol: 'د.ج',
    label: 'Algerian Dinar',
  },
  egp: { currency: EGP, locale: 'ar-EG', symbol: '£', label: 'Egyptian Pound' },
  etb: {
    currency: ETB,
    locale: 'am-ET',
    symbol: 'Br',
    label: 'Ethiopian Birr',
  },
  eur: { currency: EUR, locale: 'en-IE', symbol: '€', label: 'Euro' },
  fjd: { currency: FJD, locale: 'en-FJ', symbol: '$', label: 'Fijian Dollar' },
  fkp: {
    currency: FKP,
    locale: 'en-FK',
    symbol: '£',
    label: 'Falkland Islands Pound',
  },
  gbp: { currency: GBP, locale: 'en-GB', symbol: '£', label: 'British Pound' },
  gel: { currency: GEL, locale: 'ka-GE', symbol: '₾', label: 'Georgian Lari' },
  gip: {
    currency: GIP,
    locale: 'en-GI',
    symbol: '£',
    label: 'Gibraltar Pound',
  },
  gmd: { currency: GMD, locale: 'en-GM', symbol: 'D', label: 'Gambian Dalasi' },
  gnf: { currency: GNF, locale: 'fr-GN', symbol: 'FG', label: 'Guinean Franc' },
  gtq: {
    currency: GTQ,
    locale: 'es-GT',
    symbol: 'Q',
    label: 'Guatemalan Quetzal',
  },
  gyd: {
    currency: GYD,
    locale: 'en-GY',
    symbol: '$',
    label: 'Guyanese Dollar',
  },
  hkd: {
    currency: HKD,
    locale: 'en-HK',
    symbol: 'HK$',
    label: 'Hong Kong Dollar',
  },
  hnl: {
    currency: HNL,
    locale: 'es-HN',
    symbol: 'L',
    label: 'Honduran Lempira',
  },
  htg: { currency: HTG, locale: 'ht-HT', symbol: 'G', label: 'Haitian Gourde' },
  huf: {
    currency: HUF,
    locale: 'hu-HU',
    symbol: 'Ft',
    label: 'Hungarian Forint',
  },
  idr: {
    currency: IDR,
    locale: 'id-ID',
    symbol: 'Rp',
    label: 'Indonesian Rupiah',
  },
  ils: {
    currency: ILS,
    locale: 'he-IL',
    symbol: '₪',
    label: 'Israeli New Shekel',
  },
  inr: { currency: INR, locale: 'en-IN', symbol: '₹', label: 'Indian Rupee' },
  isk: {
    currency: ISK,
    locale: 'is-IS',
    symbol: 'kr',
    label: 'Icelandic Króna',
  },
  jmd: {
    currency: JMD,
    locale: 'en-JM',
    symbol: '$',
    label: 'Jamaican Dollar',
  },
  jpy: { currency: JPY, locale: 'ja-JP', symbol: '¥', label: 'Japanese Yen' },
  kes: {
    currency: KES,
    locale: 'en-KE',
    symbol: 'KSh',
    label: 'Kenyan Shilling',
  },
  kgs: {
    currency: KGS,
    locale: 'ky-KG',
    symbol: 'сом',
    label: 'Kyrgyzstani Som',
  },
  khr: { currency: KHR, locale: 'km-KH', symbol: '៛', label: 'Cambodian Riel' },
  kmf: {
    currency: KMF,
    locale: 'fr-KM',
    symbol: 'CF',
    label: 'Comorian Franc',
  },
  krw: {
    currency: KRW,
    locale: 'ko-KR',
    symbol: '₩',
    label: 'South Korean Won',
  },
  kyd: {
    currency: KYD,
    locale: 'en-KY',
    symbol: '$',
    label: 'Cayman Islands Dollar',
  },
  kzt: {
    currency: KZT,
    locale: 'kk-KZ',
    symbol: '₸',
    label: 'Kazakhstani Tenge',
  },
  lak: { currency: LAK, locale: 'lo-LA', symbol: '₭', label: 'Lao Kip' },
  lbp: {
    currency: LBP,
    locale: 'ar-LB',
    symbol: 'ل.ل',
    label: 'Lebanese Pound',
  },
  lkr: {
    currency: LKR,
    locale: 'si-LK',
    symbol: 'රු',
    label: 'Sri Lankan Rupee',
  },
  lrd: {
    currency: LRD,
    locale: 'en-LR',
    symbol: '$',
    label: 'Liberian Dollar',
  },
  lsl: { currency: LSL, locale: 'en-LS', symbol: 'M', label: 'Lesotho Loti' },
  mad: {
    currency: MAD,
    locale: 'ar-MA',
    symbol: 'د.م.',
    label: 'Moroccan Dirham',
  },
  mdl: { currency: MDL, locale: 'ro-MD', symbol: 'L', label: 'Moldovan Leu' },
  mga: {
    currency: MGA,
    locale: 'mg-MG',
    symbol: 'Ar',
    label: 'Malagasy Ariary',
  },
  mkd: {
    currency: MKD,
    locale: 'mk-MK',
    symbol: 'ден',
    label: 'Macedonian Denar',
  },
  mmk: { currency: MMK, locale: 'my-MM', symbol: 'Ks', label: 'Myanmar Kyat' },
  mnt: {
    currency: MNT,
    locale: 'mn-MN',
    symbol: '₮',
    label: 'Mongolian Tögrög',
  },
  mop: {
    currency: MOP,
    locale: 'zh-MO',
    symbol: 'MOP$',
    label: 'Macanese Pataca',
  },
  mur: {
    currency: MUR,
    locale: 'en-MU',
    symbol: '₨',
    label: 'Mauritian Rupee',
  },
  mvr: {
    currency: MVR,
    locale: 'dv-MV',
    symbol: 'ރ.',
    label: 'Maldivian Rufiyaa',
  },
  mwk: {
    currency: MWK,
    locale: 'en-MW',
    symbol: 'MK',
    label: 'Malawian Kwacha',
  },
  mxn: { currency: MXN, locale: 'es-MX', symbol: '$', label: 'Mexican Peso' },
  myr: {
    currency: MYR,
    locale: 'ms-MY',
    symbol: 'RM',
    label: 'Malaysian Ringgit',
  },
  mzn: {
    currency: MZN,
    locale: 'pt-MZ',
    symbol: 'MT',
    label: 'Mozambican Metical',
  },
  nad: {
    currency: NAD,
    locale: 'en-NA',
    symbol: '$',
    label: 'Namibian Dollar',
  },
  ngn: { currency: NGN, locale: 'en-NG', symbol: '₦', label: 'Nigerian Naira' },
  nio: {
    currency: NIO,
    locale: 'es-NI',
    symbol: 'C$',
    label: 'Nicaraguan Córdoba',
  },
  nok: {
    currency: NOK,
    locale: 'nb-NO',
    symbol: 'kr',
    label: 'Norwegian Krone',
  },
  npr: { currency: NPR, locale: 'ne-NP', symbol: '₨', label: 'Nepalese Rupee' },
  nzd: {
    currency: NZD,
    locale: 'en-NZ',
    symbol: 'NZ$',
    label: 'New Zealand Dollar',
  },
  pab: {
    currency: PAB,
    locale: 'es-PA',
    symbol: 'B/.',
    label: 'Panamanian Balboa',
  },
  pen: { currency: PEN, locale: 'es-PE', symbol: 'S/', label: 'Peruvian Sol' },
  pgk: {
    currency: PGK,
    locale: 'en-PG',
    symbol: 'K',
    label: 'Papua New Guinean Kina',
  },
  php: {
    currency: PHP,
    locale: 'en-PH',
    symbol: '₱',
    label: 'Philippine Peso',
  },
  pkr: {
    currency: PKR,
    locale: 'ur-PK',
    symbol: '₨',
    label: 'Pakistani Rupee',
  },
  pln: { currency: PLN, locale: 'pl-PL', symbol: 'zł', label: 'Polish Złoty' },
  pyg: {
    currency: PYG,
    locale: 'es-PY',
    symbol: '₲',
    label: 'Paraguayan Guaraní',
  },
  qar: { currency: QAR, locale: 'ar-QA', symbol: 'ر.ق', label: 'Qatari Riyal' },
  ron: { currency: RON, locale: 'ro-RO', symbol: 'lei', label: 'Romanian Leu' },
  rsd: {
    currency: RSD,
    locale: 'sr-RS',
    symbol: 'дин.',
    label: 'Serbian Dinar',
  },
  rub: { currency: RUB, locale: 'ru-RU', symbol: '₽', label: 'Russian Ruble' },
  rwf: {
    currency: RWF,
    locale: 'rw-RW',
    symbol: 'FRw',
    label: 'Rwandan Franc',
  },
  sar: { currency: SAR, locale: 'ar-SA', symbol: 'ر.س', label: 'Saudi Riyal' },
  sbd: {
    currency: SBD,
    locale: 'en-SB',
    symbol: '$',
    label: 'Solomon Islands Dollar',
  },
  scr: {
    currency: SCR,
    locale: 'en-SC',
    symbol: '₨',
    label: 'Seychellois Rupee',
  },
  sek: { currency: SEK, locale: 'sv-SE', symbol: 'kr', label: 'Swedish Krona' },
  sgd: {
    currency: SGD,
    locale: 'en-SG',
    symbol: '$',
    label: 'Singapore Dollar',
  },
  shp: {
    currency: SHP,
    locale: 'en-SH',
    symbol: '£',
    label: 'Saint Helena Pound',
  },
  sos: {
    currency: SOS,
    locale: 'so-SO',
    symbol: 'S',
    label: 'Somali Shilling',
  },
  srd: {
    currency: SRD,
    locale: 'en-SR',
    symbol: 'SRD',
    label: 'Surinamese Dollar',
  },
  szl: {
    currency: SZL,
    locale: 'en-SZ',
    symbol: 'L',
    label: 'Swazi Lilangeni',
  },
  thb: { currency: THB, locale: 'th-TH', symbol: '฿', label: 'Thai Baht' },
  tjs: {
    currency: TJS,
    locale: 'tg-TJ',
    symbol: 'ЅМ',
    label: 'Tajikistani Somoni',
  },
  top: {
    currency: TOP,
    locale: 'to-TO',
    symbol: 'T$',
    label: 'Tongan Paʻanga',
  },
  try: { currency: TRY, locale: 'tr-TR', symbol: '₺', label: 'Turkish Lira' },
  ttd: {
    currency: TTD,
    locale: 'en-TT',
    symbol: '$',
    label: 'Trinidad and Tobago Dollar',
  },
  twd: {
    currency: TWD,
    locale: 'en-TW',
    symbol: 'NT$',
    label: 'New Taiwan Dollar',
  },
  tzs: {
    currency: TZS,
    locale: 'sw-TZ',
    symbol: 'TSh',
    label: 'Tanzanian Shilling',
  },
  uah: {
    currency: UAH,
    locale: 'uk-UA',
    symbol: '₴',
    label: 'Ukrainian Hryvnia',
  },
  ugx: {
    currency: UGX,
    locale: 'en-UG',
    symbol: 'USh',
    label: 'Ugandan Shilling',
  },
  uyu: {
    currency: UYU,
    locale: 'es-UY',
    symbol: '$U',
    label: 'Uruguayan Peso',
  },
  uzs: {
    currency: UZS,
    locale: 'uz-UZ',
    symbol: 'som',
    label: 'Uzbekistani Som',
  },
  vnd: {
    currency: VND,
    locale: 'vi-VN',
    symbol: '₫',
    label: 'Vietnamese Đồng',
  },
  vuv: { currency: VUV, locale: 'bi-VU', symbol: 'VT', label: 'Vanuatu Vatu' },
  wst: { currency: WST, locale: 'sm-WS', symbol: 'WS$', label: 'Samoan Tālā' },
  xaf: {
    currency: XAF,
    locale: 'fr-CM',
    symbol: 'FCFA',
    label: 'Central African CFA Franc',
  },
  xcd: {
    currency: XCD,
    locale: 'en-AG',
    symbol: '$',
    label: 'East Caribbean Dollar',
  },
  xof: {
    currency: XOF,
    locale: 'fr-SN',
    symbol: 'CFA',
    label: 'West African CFA Franc',
  },
  xpf: { currency: XPF, locale: 'fr-PF', symbol: '₣', label: 'CFP Franc' },
  yer: { currency: YER, locale: 'ar-YE', symbol: '﷼', label: 'Yemeni Rial' },
  zar: {
    currency: ZAR,
    locale: 'en-ZA',
    symbol: 'R',
    label: 'South African Rand',
  },
  zmw: {
    currency: ZMW,
    locale: 'en-ZM',
    symbol: 'ZK',
    label: 'Zambian Kwacha',
  },
}

const createIntlFormatter = (
  locale: string,
  dineroObject: any,
  options = {},
) => {
  const transformer = ({ value, currency }: { value: any; currency: any }) => {
    return Number(value).toLocaleString(locale, {
      ...options,
      style: 'currency',
      currency: currency.code,
    })
  }

  return toDecimal(dineroObject, transformer)
}

export const formatPriceInCents = (
  amount: number,
  currencyStr: string,
  showCurrency: boolean = false,
  showDecimals: boolean = false,
): string => {
  const hasOwnProperty = Object.prototype.hasOwnProperty.bind(currencyMap)

  if (!hasOwnProperty(currencyStr)) {
    throw new Error('Unsupported currency')
  }

  try {
    const currencyObj = currencyMap[currencyStr]!

    const roundedAmount = Math.round(amount)

    const d = dinero({ amount: roundedAmount, currency: currencyObj?.currency })

    const { currency: dineroCurrency } = toSnapshot(d)

    let formattedValue = createIntlFormatter(currencyObj.locale, d)

    // Remove '.00' if it exists
    if (formattedValue.endsWith('.00') && !showDecimals) {
      formattedValue = formattedValue.substring(0, formattedValue.length - 3)
    }

    // If showCurrency is true, append only the currency code
    if (showCurrency) {
      formattedValue += ` ${dineroCurrency.code}`
    }

    return formattedValue
  } catch (_error) {
    throw new Error(
      `Invalid parameters amount=${String(amount)} currencyStr=${String(
        currencyStr,
      )}`,
    )
  }
}

export const formatPriceString = (
  amount: string,
  currencyStr: string,
): string => {
  let foundCurrency = currencyMap['usd']

  const hasOwnProperty = Object.prototype.hasOwnProperty.bind(currencyMap)

  if (hasOwnProperty(currencyStr.toLowerCase())) {
    foundCurrency = currencyMap[currencyStr.toLowerCase()]
  }

  return `${foundCurrency.symbol}${amount}`
}

export const getCurrencySymbol = (currencyStr: string): string => {
  let foundCurrency = currencyMap[currencyStr.toLowerCase()]
  return foundCurrency.symbol
}
